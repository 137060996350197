import React, { useContext, useRef, useState } from "react"
import type { QueryParamConfig } from "use-query-params"
import {
  ArrayParam,
  StringParam,
  useQueryParam,
  useQueryParams,
  withDefault,
} from "use-query-params"
import cloneDeep from "lodash.clonedeep"
import {
  type AvailableFilter,
  type AvailableFilterOption,
} from "../alumnis/availableFilters"

export class Filters {
  year?: (string | null)[] = []
  cohort?: (string | null)[] = []
}

export type FilterKeys = keyof Filters

export type Sort = `year` | `cohort` | null | undefined
const SortParam: QueryParamConfig<Sort> = {
  encode: (value) => value,
  decode: (value) => value as Sort,
}

const aggregateFilterCount = (filters: Filters) =>
  (Object.keys(filters) as FilterKeys[]).reduce(
    (previousValue, key) => previousValue + (filters[key]?.length ?? 0),
    0
  )

export interface IFilterContext {
  filters: Filters
  // The search query
  query?: string | null
  sort?: Sort | null

  /**
   * Applies the currently selected filters to the global FilterContext
   *
   * This only applies to non-global FilterContexts (FilterAllAtOnceProvider), because the global filters are always set
   * to the most recently applied
   */
  applyFilters: () => void
  /**
   * Clears out all filters
   */
  clearFilters: () => void
  /**
   * Returns the total amount of filters applied
   */
  filterCount: () => number
  /**
   * Set filters to previously applied value.
   *
   * This only applies to non-global FilterContexts (FilterAllAtOnceProvider). @see IFilterContext.applyFilters
   */
  resetFilters: () => void
  setFilters: (filters: Partial<Filters>) => void
  setQuery: (query: string | null | undefined) => void
  setSort: (sort: Sort) => void
  /**
   * Adds or removes a filter option from the context based on the checked property passed in
   * @param available
   * @param option
   * @param checked
   */
  toggleOption: (
    available: AvailableFilter,
    option: AvailableFilterOption,
    checked: boolean
  ) => Filters
}

export const FilterContext = React.createContext<IFilterContext | undefined>(
  undefined
)

/**
 * Component that maintains the global FilterContext state
 * @param children
 * @constructor
 */
export const FilterProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [filters, setFilters] = useQueryParams({
    year: withDefault(ArrayParam, []),
    cohort: withDefault(ArrayParam, []),
  })

  const [query, setQuery] = useQueryParam(`query`, StringParam)
  const [sort, setSort] = useQueryParam(
    `sort`,
    withDefault(SortParam, `cohort`)
  )

  const applyFilters: IFilterContext[`applyFilters`] = () => {
    setFilters(filters)
  }

  const clearFilters: IFilterContext[`clearFilters`] = () => {
    setFilters(new Filters())
  }

  const resetFilters: IFilterContext[`resetFilters`] = () => {}

  const filterCount: IFilterContext[`filterCount`] = (): number =>
    aggregateFilterCount(filters)

  const toggleOption: IFilterContext[`toggleOption`] = (
    available: AvailableFilter,
    option: AvailableFilterOption,
    checked: boolean
  ) => {
    const key = available.key as keyof Filters

    if (checked) {
      filters[key] = filters[key].filter(
        (selected) => !available.equals(selected, option.value)
      )
    } else {
      filters[key] = [...filters[key], option.value]
    }
    return filters
  }

  return (
    <FilterContext.Provider
      value={{
        query,
        setQuery,
        sort,
        setSort,
        applyFilters,
        clearFilters,
        filters,
        resetFilters,
        setFilters,
        toggleOption,
        filterCount,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}

/**
 * A version of FilterProvider which maintains its own FilterContext that can then be applied to the global
 * FilterContext or reset to what the global state is
 * @param children
 * @constructor
 */
export const FilterAllAtOnceProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const {
    query,
    setQuery,
    sort,
    setSort,
    filters: pageFilters,
    setFilters: setPageFilters,
    toggleOption,
  } = useContext(FilterContext) as IFilterContext
  const originalFilters = useRef(cloneDeep(pageFilters))
  const [filters, setFilters] = useState(cloneDeep(pageFilters))

  const applyFilters = () => {
    setPageFilters(filters)
    originalFilters.current = cloneDeep(filters)
  }

  const clearFilters = () => {
    setFilters(new Filters())
  }

  const resetFilters = () => {
    setFilters(originalFilters.current)
  }

  const filterCount = (): number => aggregateFilterCount(filters)

  return (
    <FilterContext.Provider
      value={{
        query,
        setQuery,
        sort,
        setSort,
        applyFilters,
        clearFilters,
        filters,
        resetFilters,
        setFilters,
        toggleOption,
        filterCount,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}
