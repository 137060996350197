export enum AlumniCohort {
  COHORT_1 = `1`,
  COHORT_2 = `2`,
}

export enum AlumniYear {
  YEAR_2022 = `2022`,
  YEAR_2023 = `2023`,
  YEAR_2024 = `2024`,
}

export interface Alumni {
  name: string
  cohort: AlumniCohort
  year: AlumniYear
  description: string
  founders: string[]
  img: string
  url: string
}
