import React, { useContext, useId } from "react"
import type { IFilterContext } from "../filter/filterContext"
import { FilterContext } from "../filter/filterContext"
import "./AlumniFilterMenu.scss"
import {
  type AvailableFilter,
  type AvailableFilterOption,
  availableFilters,
} from "./availableFilters"

export interface AlumniFilterMenuProps {
  onApply?: () => void
  onCancel?: () => void
}

export const AlumniFilterMenu: React.FC<AlumniFilterMenuProps> = ({
  onApply,
  onCancel,
}) => {
  const uniqueId = useId()
  const {
    applyFilters,
    clearFilters,
    filters,
    setFilters,
    resetFilters,
    toggleOption,
  } = useContext(FilterContext) as IFilterContext

  const isChecked = (
    available: AvailableFilter,
    option: AvailableFilterOption
  ) =>
    filters[available.key]?.some((selected: any) =>
      available.equals(selected, option.value)
    ) ?? false

  const cancel = () => {
    resetFilters()
    onCancel?.()
  }

  const apply = () => {
    applyFilters()
    onApply?.()
  }

  return (
    <div className="filter-menu bg-white rounded flex flex-col pt-[16px]">
      <div className="flex items-start px-4 filter-header">
        Filters
        <button
          onClick={clearFilters}
          className="btn btn-tertiary btn-sm ml-auto"
        >
          Clear All
        </button>
      </div>
      <ul className="overflow-y-auto grow px-[16px]">
        {availableFilters.map((available) => (
          <li key={available.key}>
            <label className="toggle" htmlFor={`${uniqueId}-${available.key}`}>
              {available.name}
            </label>
            <input
              type="checkbox"
              className="hidden filter-group-toggle"
              id={`${uniqueId}-${available.key}`}
            />
            <div className="filter-group-options">
              {available.options.map((option) => {
                const id = `filter-${uniqueId}-${
                  available.key
                }-${option.name.replaceAll(` `, ``)}`

                const checked = isChecked(available, option)
                return (
                  <label htmlFor={id} key={id}>
                    <input
                      name="filters"
                      id={id}
                      type="checkbox"
                      checked={checked}
                      onChange={() => {
                        const filters = toggleOption(available, option, checked)
                        setFilters(Object.assign({}, filters))
                      }}
                      className="form-checkbox text-blue-purple-10 rounded border-blue-purple-20"
                    />
                    {option.name}
                  </label>
                )
              })}
            </div>
          </li>
        ))}
      </ul>
      <div className="btn-toolbar flex gap-[10px] p-[16px]">
        <button className="btn btn btn-secondary btn-cancel" onClick={cancel}>
          Cancel
        </button>
        <button className="btn btn-primary btn-apply" onClick={apply}>
          Apply Filters
        </button>
      </div>
    </div>
  )
}
