import React, { useState } from "react"
import { Link } from "gatsby"
import { Page } from "../components/layout/Page"
import { EcosystemEnd } from "../components/layout/EcosystemEnd"
import { Alumnus } from "../components/alumnis/Alumnus"
import "./alumni.scss"

export const Alumni = () => {
  const [selectedTab, setSelectedTab] = useState(`Alumnus`)
  return (
    <Page
      header=""
      title="Acclerator Ecosystem Alumni"
      keywords="ecosystem mentors, xrpl accelerator ecosystem, xrpl accelerator mentors, web3 experts, blockchain experts."
      description="Meet the XRPL Accelerator Alumni"
      slug="mentors"
    >
      <h1 className="text-center ecosystem-header">
        Accelerator <br /> Ecosystem
      </h1>
      <div className="eco-tabs">
        <Link
          to="/ecosystem"
          className={`eco-toggle mentors-toggle selected ${
            selectedTab === `Mentors` ? `selected-mentors` : ``
          }`}
          onClick={() => setSelectedTab(`Mentors`)}
        >
          Mentors
        </Link>
        <Link
          to="/partner"
          className={`eco-toggle partners-toggle selected ${
            selectedTab === `Partners` ? `selected-partners` : ``
          }`}
          onClick={() => setSelectedTab(`Partners`)}
        >
          Partners
        </Link>
        <Link
          to="/alumni"
          className={`eco-toggle alumnis-toggle selected ${
            selectedTab === `Alumnus` ? `selected-alumnis` : ``
          }`}
          onClick={() => setSelectedTab(`Alumnus`)}
        >
          Project Alumni
        </Link>
      </div>
      <Alumnus />
      <EcosystemEnd />
    </Page>
  )
}

export default Alumni
