import { type Filters, type Sort } from "../filter/filterContext"
import { type Alumni } from "./alumni"

export const filterAlumni = (
  alumni: Alumni[],
  query: string,
  sort: Sort,
  filters: Filters
) => {
  const checks: Function[] = []

  if (filters.cohort?.length) {
    checks.push(filterByCohort)
  }
  if (filters.year?.length) {
    checks.push(filterByYear)
  }

  const [, direction, sortProperty] = sort?.match(/(-)?([a-z]+)/) || []

  return alumni
    .filter(
      (alumnus: Alumni) =>
        checks.length === 0 ||
        checks.every((check) => check(alumnus, filters, query, sort))
    )
    .sort(
      (a, b) =>
        sortBy(a, b, sortProperty as Extract<Sort, keyof Alumni>, !direction) ||
        sortBy(a, b, `name`)
    )
}

const filterByCohort = (alumnus: Alumni, filters: Filters): boolean =>
  !!filters.cohort?.includes(alumnus.cohort.toString())

const filterByYear = (alumnus: Alumni, filters: Filters): boolean =>
  !!filters.year?.includes(alumnus.year ?? ``)

const sortBy = (a: Alumni, b: Alumni, prop: keyof Alumni, desc = true) => {
  const propA = a[prop] ?? ``
  const propB = b[prop] ?? ``
  let result = 0

  if (propA > propB) {
    result = -1
  } else if (propA < propB) {
    result = 1
  }

  if (desc) {
    result = result * -1
  }

  return result
}
