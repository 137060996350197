import {
  type Alumni,
  AlumniCohort,
  AlumniYear,
} from "../components/alumnis/alumni"

export const alumniData: Alumni[] = [
  {
    cohort: AlumniCohort.COHORT_1,
    year: AlumniYear.YEAR_2023,
    name: `Kudos`,
    url: `loremlabs.com`,
    founders: [`Matt Mankins`, `Sanghee Kim`, `Zac Franc`],
    description: `Kudos offers a reward system via an XRPL wallet app, allowing for stipends to contributors based on their Kudos, with a focus on open source developers, and streamlines payments by linking social identities to XRPL addresses.`,
    img: `kudos.svg`,
  },

  {
    cohort: AlumniCohort.COHORT_1,
    year: AlumniYear.YEAR_2023,
    name: `Chimoney`,
    url: `chimoney.io`,
    founders: [`Uchi Uchibeke`],
    description: `Chimoney's Unispend facilitates spending XRP and tokens from wallets like XUMM on XRPL, providing developers with tools to integrate a variety of products and remittance options from platforms like Amazon and Shopify.`,
    img: `chimoney logo.svg`,
  },
  {
    cohort: AlumniCohort.COHORT_1,
    year: AlumniYear.YEAR_2023,
    name: `Ap0cene`,
    url: `ap0cene.com`,
    founders: [
      `Sam Walker`,
      `Ariel Arakas`,
      `Elissa Rumford`,
      `Andrew Fenlon`,
      `Ryan Beck`,
    ],
    description: `Ap0cene's Web3 protocol leverages NFC and NFTs to allow instant access to garment details, supporting informed, value-driven consumer choices with global impact through a simple smartphone tap.`,
    img: `Ap0cene logo.svg`,
  },
  {
    cohort: AlumniCohort.COHORT_1,
    year: AlumniYear.YEAR_2023,
    name: `VerifyED`,
    url: `verifyed.io`,
    founders: [`Yaz El Hakim`, `Abigail Waterer`, `Michael Fordham`],
    description: `VerifyED offers blockchain-backed digital credentials on XRPLedger, enabling instant, tamper-proof verification and skill-embedded certificates for easy communication with employers.`,
    img: `VerifyED logo.svg`,
  },
  {
    cohort: AlumniCohort.COHORT_1,
    year: AlumniYear.YEAR_2023,
    name: `xrp.cafe`,
    url: `xrp.cafe`,
    founders: [
      `Adam Kagy`,
      `Sebastian Valdez`,
      `Christopher Troia`,
      `Christopher Johnson`,
      `Hussein Zangana`,
      `Steven Shipley`,
      `Jesse DiSabato`,
      `Timothy Smith Yusef`,
      `Kristi Yorks`,
    ],
    description: `xrp.cafe, a leading NFT marketplace on the XRP Ledger, simplifies access for Web2 and Web3 users, fostering mass adoption and expanding into enterprise applications to broaden its impact.`,
    img: `xrp cafe.svg`,
  },
  {
    cohort: AlumniCohort.COHORT_1,
    year: AlumniYear.YEAR_2023,
    name: `NIR-Watchdog`,
    url: `nir-watchdog.lu`,
    founders: [`Edward Berkovich`, `Jorge Augusto Meira`, `Nabil Nahhas`],
    description: `NIR-Watchdog leverages NIR Spectroscopy, machine learning, and blockchain for material authentication and tampering prevention, targeting B2B clients with counterfeit issues.`,
    img: `NIR watchdog logo.svg`,
  },
  {
    cohort: AlumniCohort.COHORT_1,
    year: AlumniYear.YEAR_2023,
    name: `Droplinked`,
    url: `droplinked.com`,
    founders: [`Ali Sammour`, `Morteza Nokhodian Esfahani`],
    description: `Droplinked connects businesses, creators, and consumers using Web3 tools for inventory, sales tracking, and Web2 to Web3 integration through token gating and loyalty programs.`,
    img: `droplinked logo.svg`,
  },
  {
    cohort: AlumniCohort.COHORT_1,
    year: AlumniYear.YEAR_2023,
    name: `CornerMarket`,
    url: `cornermarket.co`,
    founders: [
      `Moira Noiseux`,
      `Felix Li`,
      `D'Narius Lewis`,
      `Graham Patterson`,
    ],
    description: `CornerMarket is a blockchain-based marketplace aiming to lower costs for small businesses and consumers, boosting profits and community growth by eliminating intermediaries.`,
    img: `corner market logo.svg`,
  },
  {
    cohort: AlumniCohort.COHORT_1,
    year: AlumniYear.YEAR_2023,
    name: `GemWallet`,
    url: `gemwallet.app`,
    founders: [`Florian Bouron`, `Thibaut Bremand`],
    description: `GemWallet offers a web-based XRP Ledger wallet for secure payments and NFT interactions from the browser.`,
    img: `gem wallet logo.svg`,
  },
  {
    cohort: AlumniCohort.COHORT_2,
    year: AlumniYear.YEAR_2023,
    name: `Frii`,
    url: `friipay.io`,
    founders: [`Farhaan Dawood`, `Nabeel Choudhry`, `Jason Welsford`],
    description: `FriiPay revolutionizes retail transactions by enabling over 50 million POS terminals to process payments via Web 3.0 and traditional methods without extra hardware, promoting blockchain adoption through digital tools like real-time loyalty and coupons.`,
    img: `friipay logo.svg`,
  },
  {
    cohort: AlumniCohort.COHORT_2,
    year: AlumniYear.YEAR_2023,
    name: `Phi Wallet`,
    url: `phiwallet.com`,
    founders: [`Eslam Anbar`],
    description: `Phi Wallet offers a mobile solution for instant access to digital gold, facilitating savings, investments, and payments for all budgets by tokenizing gold on the blockchain and enabling gold-based payments, aiming to democratize the safety of gold through a Sharia-compliant, inflation-resistant platform.`,
    img: `phi wallet logo.svg`,
  },
  {
    cohort: AlumniCohort.COHORT_2,
    year: AlumniYear.YEAR_2023,
    name: `Kaiju Labs`,
    url: `kaiju3d.com`,
    founders: [`Sameera Nilupul`, `Chameera Wijebandara`, `Pabasara Dilshan`],
    description: `Kaiju Labs simplifies blockchain game development with a no-code toolkit and enhances player experience with an easy-to-use wallet app, aiming to reduce complexity and dropout rates in the sector.`,
    img: `kaiju labs logo.svg`,
  },
  {
    cohort: AlumniCohort.COHORT_2,
    year: AlumniYear.YEAR_2023,
    name: `EQUIL`,
    url: `equil.io`,
    founders: [`Tori West`],
    description: `EQLX is a sports performance social media on the XRP Ledger, offering athletes and fans ownership of sports data through NFT posts and EQL tokens, promoting value from engagement and democratizing the sports data economy.`,
    img: `equil labs logo.svg`,
  },
  {
    cohort: AlumniCohort.COHORT_2,
    year: AlumniYear.YEAR_2023,
    name: `Crossmark`,
    url: `crossmark.io`,
    founders: [`Ryan Molley`],
    description: `Crossmark offers a browser-first, non-custodial XRP Ledger wallet, aiming to enhance user experience, introduce a universal QR code, and support network versatility with future AMM and sidechain enhancements.`,
    img: `crossmark logo.svg`,
  },
  {
    cohort: AlumniCohort.COHORT_2,
    year: AlumniYear.YEAR_2023,
    name: `Ospree`,
    url: `ospree.io`,
    founders: [`Javier Tamashiro`, `Anne Winston`],
    description: `Ospree streamlines digital asset compliance for all institutions, unifying fragmented processes with a comprehensive solution to simplify regulatory navigation and enhance industry efficiency.`,
    img: `ospree.svg`,
  },
  {
    cohort: AlumniCohort.COHORT_2,
    year: AlumniYear.YEAR_2023,
    name: `Mandla Money`,
    url: `mandla.money/web`,
    founders: [`Julian Kanjere`, `Kunta-Kinte Ngcobo`, `Nande Hangana`],
    description: `Mandla SMS Wallet offers a white-label solution for stablecoin and CBDC transactions via SMS, targeting financial inclusion by eliminating the need for internet or smartphones.`,
    img: `mandla money.svg`,
  },
  {
    cohort: AlumniCohort.COHORT_2,
    year: AlumniYear.YEAR_2023,
    name: `Wind`,
    url: `wind.app`,
    founders: [`Hussain Elius`, `Fahad Ahmed`, `Simo Figuigui`],
    description: `Wind.app enables efficient cross-border payments on Web3, using a self-custodial wallet for fast, low-cost transactions, focusing on the US-Southeast Asia remittance corridor.`,
    img: `wind logo.svg`,
  },
  {
    cohort: AlumniCohort.COHORT_2,
    year: AlumniYear.YEAR_2023,
    name: `SAFEHaven`,
    url: `mysafehavenwallet.com`,
    founders: [`KC Wong`],
    description: `SAFEhaven transforms old mobile devices into free, secure cold wallets, offering a user-friendly platform that balances convenience and security for blockchain interaction and digital identity, aiming to boost mass adoption.`,
    img: `safehaven logo.svg`,
  },
  {
    cohort: AlumniCohort.COHORT_2,
    year: AlumniYear.YEAR_2023,
    name: `D3 Labs`,
    url: `d3labs.io`,
    founders: [`Chris Tang`, `Jeremy Low`, `Khun Ling Tan`],
    description: `D3 Labs' Seaseed product enhances real-time value transfer by integrating digital currencies into remittances, enabling efficient, 24/7 cross-border transactions for improved business efficiency and global connectivity.`,
    img: `d3 labs.svg`,
  },
  {
    cohort: AlumniCohort.COHORT_2,
    year: AlumniYear.YEAR_2023,
    name: `StaykX`,
    url: `staykx.com`,
    founders: [`Shen Morincome`],
    description: `StaykX, a DeFi protocol on the XRPL, introduces 'Soft-Staking' for daily rewards with full asset control, integrating with 50+ projects to enhance community engagement while ensuring user security through self-custodial features.`,
    img: `Staykx logo.svg`,
  },
]
