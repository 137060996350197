import type { ChangeEvent, KeyboardEvent } from "react"
import React, { useContext, useRef, useEffect } from "react"
import type { Sort } from "../filter/filterContext"
import {
  FilterAllAtOnceProvider,
  FilterContext,
  FilterProvider,
  type IFilterContext,
} from "../filter/filterContext"
import FilterIcon from "../../images/filter_icon.inline.svg"
import "./alumni.scss"
import { alumniData } from "../../data/alumniData"
import { filterAlumni } from "./filterAlumni"
import { type Alumni } from "./alumni"
import { AlumniFilterMenu } from "./AlumnifilterMenu"

const AlumniList = () => {
  const { filters, query, setQuery, setSort, sort } = useContext(
    FilterContext
  ) as IFilterContext
  const inputEl = useRef<HTMLInputElement>(null)
  const mobileMenu = useRef<HTMLDivElement>(null)

  function search() {
    setQuery(inputEl.current?.value)
  }

  function resetSearch() {
    if (!inputEl.current?.value.length) {
      setQuery(undefined)
    }
  }

  function toggleMenu() {
    document.body.classList.toggle(`fixed`)
    mobileMenu.current?.classList.toggle(`show`)
  }

  const aluminus = filterAlumni(
    alumniData as Alumni[],
    query ?? ``,
    sort ?? undefined,
    filters
  )

  useEffect(() => {
    document.body.addEventListener(`search`, resetSearch)
    return () => document.body.removeEventListener(`search`, resetSearch)
  }, [])

  return (
    <>
      <div className="lg:flex-grid lg:flex-row flex flex-col gap-4">
        <div className="basis-1/1 lg:basis-2/3 flex gap-4">
          <button
            className="lg:hidden form-button btn-filter-menu"
            onClick={toggleMenu}
          >
            <FilterIcon />
          </button>
        </div>
      </div>
      <div className="alumni-list grid grid-cols-2 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 gap-4">
        {aluminus.map((alumni: Alumni) => (
          <div key={alumni.name} className="flex flex-col items-center">
            <img
              src={`/images/alumnis/${alumni.img}`}
              className="alumni-logo bw-square"
              alt={alumni.name}
            />
            <p className="pb-4 alumni-name ">{alumni.name}</p>
            <p className="alumni-description text-center">
              {alumni.description}
            </p>
            <p className="alumni-founders text-center">
              <strong>Founders:</strong> {alumni.founders.join(`, `)}
            </p>
            <div className="alumni-link-container">
              <a href={`https://${alumni.url}`} className="alumni-link">
                {alumni.url}
              </a>
            </div>
          </div>
        ))}
      </div>
      <div className="mobile-menu" ref={mobileMenu}>
        <FilterAllAtOnceProvider>
          <AlumniFilterMenu onApply={toggleMenu} onCancel={toggleMenu} />
        </FilterAllAtOnceProvider>
      </div>
    </>
  )
}

export const Alumnus: React.FC = () => (
  <section>
    <FilterProvider>
      <section className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-8 lg:grid-cols-12">
        <div className="hidden lg:block lg:col-span-3">
          <AlumniFilterMenu />
        </div>
        <div className="col-span-2 sm:col-span-4 md:col-span-8 lg:col-span-9">
          <AlumniList />
        </div>
      </section>
    </FilterProvider>
  </section>
)

export default Alumnus
