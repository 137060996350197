import type { FilterKeys } from "../filter/filterContext"
import { type Alumni, AlumniCohort, AlumniYear } from "./alumni"

export interface AvailableFilterOption<T = any> {
  name: string
  value: T
}

const LATEST_COHORT = 2
const YEAR_RANGE = 2

export abstract class AvailableFilter<
  T extends Object = any,
  O extends AvailableFilterOption<T> = AvailableFilterOption<T>
> {
  constructor(
    public key: FilterKeys,
    public name: string,
    public options: O[]
  ) {}

  equals(a: T, b: T): boolean {
    return a == b
  }

  format(option: O): string {
    return option.name
  }
}

export class GeneralAvailableFilter extends AvailableFilter<string> {
  constructor(
    public key: FilterKeys,
    public name: string,
    public options: AvailableFilterOption<string>[]
  ) {
    super(key, name, options)
  }
}

export class CohortAvailableFilter extends AvailableFilter<number> {
  format(option: AvailableFilterOption<number>) {
    return `Cohort ${option.value}`
  }
}

export class YearAvailableFilter extends AvailableFilter<number> {
  format(option: AvailableFilterOption<number>) {
    return `Year ${option.value}`
  }
}

export const availableFilters = [
  new CohortAvailableFilter(
    `cohort` as FilterKeys,
    `Cohort`,
    new Array(LATEST_COHORT)
      .fill(null)
      .map<AvailableFilterOption<number>>((_, index) => {
        return {
          name: `Cohort ${index + 1}`,
          value: index + 1,
        }
      })
  ),
  new YearAvailableFilter(
    `year` as FilterKeys,
    `Year`,
    new Array(YEAR_RANGE).fill(null).map<AvailableFilterOption>((_, index) => {
      // Use _ for unused parameters and add index
      return {
        name: `${2022 + index}`,
        value: 2022 + index,
      }
    })
  ),
]
